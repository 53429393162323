<template>
    <property-card
        :show-imprint-link="true"
        :property="property"
        :show-address="showAddress"
        :show-open-in-map="showOpenInMap"
        :show-checkin-until="showCheckinUntil"
        :show-image="showImage"
        :show-carousel="showCarousel"
        :swiper-toggle-visible="swiperToggleVisible"
        :swiper-initial-status="swiperInitialStatus"
        borderClass="border border-r-gray-light"
    >
        <template v-slot:before>
            <slot name="before"></slot>
        </template>
        <template v-slot:top>
            <slot name="top"></slot>
        </template>
        <template v-slot:after>
            <div class="grid gap-5">
                <div
                    v-if="showDates"
                    class="border-r-gray-light border rounded-lg grid grid-cols-2 divide-x divide-r-gray-light"
                >
                    <div class="p-3 grid gap-2">
                        <div class="text-r-gray-medium">
                            {{ $t("new_reservation.dates.check_in_label") }}
                        </div>
                        <div>
                            <div>
                                {{
                                    date(
                                        booking.offer.arrival,
                                        "date_localized"
                                    )
                                }}
                            </div>
                            <div>
                                {{
                                    $t("common.checkin_time", {
                                        time: date(
                                            booking.offer.arrival,
                                            "time_localized"
                                        ),
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="p-3 grid gap-2">
                        <div class="text-r-gray-medium">
                            {{ $t("new_reservation.dates.check_out_label") }}
                        </div>
                        <div>
                            <div>
                                {{
                                    date(
                                        booking.offer.departure,
                                        "date_localized"
                                    )
                                }}
                            </div>
                            <div>
                                {{
                                    $t("common.checkout_time", {
                                        time: date(
                                            booking.offer.departure,
                                            "time_localized"
                                        ),
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showRoomCount"
                    class="border-r-gray-light border rounded-lg grid"
                >
                    <div class="p-3 grid gap-2">
                        <div class="text-r-gray-medium">
                            {{ $t("new_reservation.offers.rooms_count_label") }}
                        </div>
                        <div>
                            <div>
                                {{ booking.rooms }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showGuests"
                    class="border-r-gray-light border rounded-lg grid"
                >
                    <div class="p-3 grid gap-2">
                        <div
                            class="text-r-gray-medium flex flex-row items-center justify-between"
                        >
                            <div class="select-none">
                                {{
                                    $tc(
                                        "new_reservation.booking_summary.guest.guest_list_heading",
                                        booking.reservations.length
                                    )
                                }}
                            </div>
                            <div @click.stop="guestsOpen = !guestsOpen">
                                <font-awesome-icon
                                    :icon="
                                        guestsOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                ></font-awesome-icon>
                            </div>
                        </div>
                        <div v-if="guestsOpen">
                            <div v-for="reservation in booking.reservations">
                                {{ reservation.primaryGuest.firstName }}
                                {{ reservation.primaryGuest.lastName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showBooker"
                    class="border-r-gray-light border rounded-lg grid"
                >
                    <div class="p-3 grid gap-2">
                        <div
                            class="text-r-gray-medium flex flex-row items-center justify-between"
                        >
                            <div>
                                {{ $t("new_reservation.booker.heading") }}
                            </div>
                            <div @click.stop="bookerOpen = !bookerOpen">
                                <font-awesome-icon
                                    :icon="
                                        bookerOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                ></font-awesome-icon>
                            </div>
                        </div>
                        <div v-if="bookerOpen">
                            <div v-for="line in bookerAddress">
                                {{ line }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showTotalAmount" class="grid gap-2 py-5">
                    <div class="text-r-green text-3xl font-bold text-center">
                        {{ currency(payment_amount, payment_currency) }}
                    </div>
                    <div
                        class="text-r-gray-medium flex flex-row items-center gap-1 justify-center"
                    >
                        <span>
                            {{
                                $t(
                                    "new_reservation.booking_summary.total_amount.total_amount_label"
                                )
                            }}
                        </span>

                        <span>
                            {{
                                $tc(
                                    "new_reservation.booking_summary.total_amount.total_nights",
                                    booking.nights
                                )
                            }}
                        </span>
                        <span>&</span>
                        <span>
                            {{
                                $tc(
                                    "new_reservation.booking_summary.total_amount.total_rooms",
                                    booking.rooms
                                )
                            }}
                        </span>
                    </div>
                </div>
                <div
                    v-if="showInformation"
                    class="grid gap-2 py-5 border-t border-r-gray-light"
                >
                    <div class="font-bold text-sm">
                        {{
                            $t("new_reservation.offers.offer_card.information")
                        }}
                    </div>

                    <div class="text-xs flex flex-col">
                        <div class="inline-flex gap-2 items-center">
                            <icon
                                icon="check-circle"
                                :class="informationIconClass"
                            ></icon>
                            <span>
                                {{
                                    $t(
                                        "apaleo.cancellationFee." +
                                            booking.offer.cancellationFee.code +
                                            ".description"
                                    )
                                }}
                            </span>
                        </div>
                        <div class="inline-flex gap-2 items-center">
                            <icon
                                :class="informationIconClass"
                                icon="check-circle"
                            >
                            </icon>
                            <span>
                                {{
                                    $t(
                                        "new_reservation.offers.offer_card.price_information"
                                    )
                                }}
                            </span>
                        </div>
                        <div
                            class="inline-flex gap-2 items-center"
                            v-if="booking.offer.hasOwnProperty('cityTaxes')"
                            v-for="cityTax in booking.offer.cityTaxes"
                        >
                            <icon
                                :class="informationIconClass"
                                icon="check-circle"
                            >
                            </icon>
                            <span>
                                {{
                                    $t(
                                        "new_reservation.offers.offer_card.plus_city_tax",
                                        {
                                            amount: currency(
                                                cityTax.totalGrossAmount.amount,
                                                cityTax.totalGrossAmount
                                                    .currency
                                            ),
                                        }
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </property-card>
</template>
<script>
import ContentContainer from "@guest/common/ContentContainer";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import PropertyCard from "@guest/PropertyCard.vue";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import BookingPaymentAmount from "@mixins/BookingPaymentAmount";
import Icon from "@guest/common/Icon";

export default {
    mixins: [DayJS, BookingPaymentAmount, Currency],
    components: {
        ContentContainer,
        FullWidthContainer,
        PropertyCard,
        Icon,
    },
    data: function () {
        return {
            guestsOpen: true,
            bookerOpen: true,
        };
    },
    computed: {
        bookerAddress() {
            let address = [];
            if (this.booking.booker.companyName) {
                address.push(this.booking.booker.companyName);
            }
            if (this.booking.booker.firstName) {
                address.push(
                    this.booking.booker.firstName +
                        " " +
                        this.booking.booker.lastName
                );
            }
            address.push(this.booking.booker.addressLine1);
            let city = [];
            if (this.booking.booker.countryCode.toLowerCase() !== "de") {
                city.push(this.booking.booker.countryCode);
            }
            city.push(this.booking.booker.postalCode);
            city.push(this.booking.booker.city);
            address.push(city.join(" "));
            if (this.booking.booker.companyTaxId) {
                address.push(this.booking.booker.companyTaxId);
            }
            return address;
        },
    },
    props: {
        property: {
            required: true,
            type: Object,
        },
        type: {
            required: false,
            default: "booking",
        },
        reservation: {
            required: false,
            default: null,
            type: null | Object,
        },
        booking: {
            required: false,
            type: Object,
        },
        showDates: {
            required: false,
            default: false,
        },
        showRoomCount: {
            required: false,
            default: false,
        },
        showTotalAmount: {
            required: false,
            default: false,
        },
        showInformation: {
            required: false,
            default: false,
        },
        showImprintLink: {
            required: false,
            default: false,
        },
        showGuests: {
            required: false,
            default: false,
        },
        showBooker: {
            required: false,
            default: false,
        },
        showGuestsInitialState: {
            required: false,
            default: true,
        },
        showBookerInitialState: {
            required: false,
            default: true,
        },
        showAddress: {
            required: false,
            default: false,
        },
        showOpenInMap: {
            required: false,
            default: false,
        },
        showCheckinUntil: {
            required: false,
            default: false,
            type: Boolean,
        },
        showImage: {
            required: false,
            type: Boolean,
            default: false,
        },
        showCarousel: {
            required: false,
            type: Boolean,
            default: false,
        },
        informationIconClass: {
            required: false,
            default: "w-5 h-6 stroke-r-black",
        },
        swiperInitialStatus: {
            required: false,
            default: true,
        },
        swiperToggleVisible: {
            required: false,
            default: false,
        },
    },
    mounted() {
        this.guestsOpen = this.showGuestsInitialState;
        this.bookerOpen = this.showBookerInitialState;
    },
};
</script>
