<template>
    <div
        :class="[dummy ? 'opacity-50' : '']"
        class="p-5 lg:py-16 lg:px-8 grid gap-5 lg:gap-12"
    >
        <h2
            class="text-2xl lg:text-3xl italic pb-4 font-black text-r-green text-center py-4"
        >
            {{ $t("new_reservation.offers.our_offers") }}
        </h2>
        <div
            :class="[dummy ? 'divide-r-gray-medium' : 'divide-r-black']"
            class="grid grid-cols-1 lg:grid-cols-2 divide-y lg:divide-x lg:divide-y-0"
        >
            <div class="lg:pr-5 pb-3 lg:pb-0">
                <app-input
                    type="onlyValue"
                    name="roomSelect"
                    :required="true"
                    :label="$t('new_reservation.offers.rooms_count_label')"
                >
                    <div class="grid grid-cols-2 gap-3">
                        <app-button
                            v-for="room in [1, 2, 3, 4, 5, 6, 7, 8]"
                            v-if="room <= offer.availableUnits"
                            :key="room"
                            color="darkgreen"
                            @click="
                                $emit('select', {
                                    offer: offer,
                                    rooms: room,
                                })
                            "
                            :opacity="
                                rooms == room && equalOffer
                                    ? 'opacity-100'
                                    : 'opacity-50'
                            "
                            :disabled="room > offer.availableUnits || dummy"
                        >
                            {{ room }}
                            {{
                                $tc("new_reservation.offers.rooms_label", room)
                            }}
                        </app-button>
                        <div class="col-span-full">
                            <alert type="error" v-if="4 > offer.availableUnits">
                                {{
                                    $tc(
                                        "new_reservation.offers.available_rooms_text",
                                        offer.availableUnits
                                    )
                                }}
                            </alert>
                        </div>
                    </div>
                </app-input>
                <div v-if="!dummy" class="text-center text-sm">
                    {{
                        $t(
                            "new_reservation.offers.offer_card.available_rooms_count",
                            {
                                available_rooms: offer.availableUnits,
                                room_count: property.room_count,
                            }
                        )
                    }}
                </div>
            </div>

            <!-- Offer Text -->
            <div class="py-5 lg:pl-5">
                <div v-if="!dummy">
                    <div class="text-lg font-bold text-r-green">
                        {{
                            apaleoTranslate(
                                offer.roatel.unit_group.name_trans,
                                currentLocale.key
                            )
                        }}
                    </div>
                    <div class="text-lg font-bold mb-2 text-r-green">
                        {{
                            currency(
                                offer.totalGrossAmount.amount /
                                    offer.timeSlices.length,
                                offer.totalGrossAmount.currency
                            )
                        }}
                        {{ $t("new_reservation.dates.per_night") }}
                        <template
                            v-if="offer.cityTaxes && offer.cityTaxes.length > 0"
                        >
                            <div v-for="tax in offer.cityTaxes" class="text-sm">
                                {{
                                    $t(
                                        "new_reservation.offers.offer_card.plus_city_tax_per_night",
                                        {
                                            amount: currency(
                                                tax.totalGrossAmount.amount /
                                                    tax.dates.length,
                                                tax.totalGrossAmount.currency
                                            ),
                                        }
                                    )
                                }}
                            </div>
                        </template>
                    </div>
                    <div
                        id="features"
                        class="flex flex-row gap-2 flex-wrap pt-4 pb-8"
                    >
                        <div
                            v-for="feature in features"
                            class="bg-r-gray-token text-r-gray-pretty-dark py-1 px-2 inline-flex gap-0.5 items-center text-xs rounded-full"
                        >
                            <icon
                                class="stroke-r-gray-pretty-dark w-4 h-4"
                                :icon="feature.icon"
                            ></icon>
                            <span>
                                {{
                                    $t(
                                        "new_reservation.offers.offer_card.feature_list." +
                                            feature.key
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="text-xs grid gap-2">
                        <div class="font-bold text-sm">
                            {{
                                $t(
                                    "new_reservation.offers.offer_card.information"
                                )
                            }}
                        </div>

                        <div class="inline-flex gap-2 items-center">
                            <icon
                                icon="check-circle"
                                class="stroke-r-black w-5 h-5"
                            ></icon>
                            <span>
                                {{
                                    $t(
                                        "apaleo.cancellationFee." +
                                            offer.cancellationFee.code +
                                            ".description"
                                    )
                                }}
                            </span>
                        </div>
                        <div class="inline-flex gap-2 items-center">
                            <icon
                                icon="check-circle"
                                class="stroke-r-black w-5 h-5"
                            ></icon>
                            <span>
                                {{
                                    $t(
                                        "new_reservation.offers.offer_card.price_information"
                                    )
                                }}
                            </span>
                        </div>
                        <div
                            class="inline-flex gap-2 items-center"
                            v-if="offer.hasOwnProperty('cityTaxes')"
                            v-for="cityTax in offer.cityTaxes"
                        >
                            <icon
                                icon="check-circle"
                                class="stroke-r-black w-5 h-5"
                            ></icon>
                            <span>
                                {{
                                    $t(
                                        "new_reservation.offers.offer_card.plus_city_tax",
                                        {
                                            amount: currency(
                                                cityTax.totalGrossAmount.amount,
                                                cityTax.totalGrossAmount
                                                    .currency
                                            ),
                                        }
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else class="animate-pulse opacity-50">
                    <div class="text-2xl font-bold grid gap-3">
                        <div class="h-4 w-1/3 bg-r-gray-light"></div>
                        <div class="h-4 w-2/4 bg-r-gray-light"></div>
                        <div class="flex flex-wrap gap-3">
                            <div
                                v-for="i in [1, 2, 3, 4, 5]"
                                :key="i"
                                class="h-4 rounded-full w-2/6 bg-r-gray-light"
                            ></div>
                        </div>
                        <div class="mt-3 grid gap-2">
                            <div class="h-3 w-1/5 bg-r-gray-light"></div>
                            <div class="h-3 w-4/5 bg-r-gray-light"></div>
                            <div class="h-3 w-4/6 bg-r-gray-light"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ContentContainer from "@guest/common/ContentContainer";
import AppInput from "@guest/common/Input";
import AppButton from "@guest/common/Button";
import DayJS from "@mixins/DayJS";
import Currency from "@mixins/Currency";
import ClipContainer from "@guest/common/ClipContainer";
import Alert from "@guest/common/Alert";
import Icon from "@guest/common/Icon";

export default {
    components: {
        ContentContainer,
        AppInput,
        AppButton,
        ClipContainer,
        Alert,
        Icon,
    },
    mixins: [DayJS, Currency],
    data: function () {
        return {
            features: [
                { key: "single_room_shower", icon: "bed" },
                { key: "ac", icon: "propeller" },
                { key: "wifi", icon: "wifi" },
                { key: "tv", icon: "tv" },
            ],
        };
    },
    computed: {
        ...mapState({
            //offers: state => state.bookingDraft.offers,
            //property: state => state.bookingDraft.property,
            //dates: state => state.bookingDraft.dates,
        }),
        ...mapGetters({
            currentLocale: "currentLocale",
        }),
        equalOffer() {
            return _.isEqual(this.offer, this.selected);
        },
    },
    methods: {},
    props: {
        rooms: {
            required: true,
        },
        offer: {
            required: true,
            type: Object,
        },
        selected: {
            required: true,
            type: Object,
        },
        dummy: {
            required: false,
            default: false,
            type: Boolean,
        },
        property: {
            required: true,
            type: Object,
        },
    },
};
</script>

<style></style>
