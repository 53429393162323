<template>
    <div class="text-r-black">
        <full-width-container>
            <div
                class="relative max-h-screen h-auto w-full justify-self-center justify-center hidden lg:flex"
            >
                <img
                    src="/img/home_bg.jpeg"
                    alt=""
                    class="object-cover w-full"
                />
                <div
                    class="absolute inset-0 bg-gradient-to-b from-transparent to-black"
                ></div>

                <content-container
                    class="absolute mx-auto xl:bottom-16 lg:bottom-5 self-end"
                >
                    <home-booking> </home-booking>
                </content-container>
            </div>

            <!-- MOBILE -->
            <div
                class="pb-40 w-full justify-self-start items-start lg:hidden bg-black relative"
            >
                <div class="absolute bg-black">
                    <img
                        src="/img/home_bg.jpeg"
                        alt=""
                        class="object-cover w-full"
                    />
                    <div
                        class="absolute inset-0 bg-gradient-to-b from-transparent to-black"
                    ></div>
                </div>

                <content-container class="top-36 relative">
                    <home-booking> </home-booking>
                </content-container>
            </div>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container>
                <div class="py-8 text-center">
                    <h2
                        class="font-sans italic font-black text-r-green text-3xl lg:text-4xl py-8"
                    >
                        {{ $t("home.microhotels.heading") }}
                    </h2>
                    <p class="text-lg leading-8">
                        {{ $t("home.microhotels.body1") }}<br />
                        {{ $t("home.microhotels.body2") }}
                    </p>
                </div>
            </content-container>
        </full-width-container>
        <!-- //Advantages and rating -->
        <full-width-container class="bg-r-gray-ultralight">
            <content-container>
                <div class="grid lg:grid-cols-2 gap-5">
                    <div
                        class="bg-white border-r-gray-medium p-8 lg:p-16 rounded-lg border"
                    >
                        <h2
                            class="font-sans italic font-black text-r-green text-center lg:text-left text-2xl lg:text-3xl"
                        >
                            {{ $t("home.advantages.heading") }}
                        </h2>

                        <p
                            class="py-6 text-lg lg:text-xl text-center lg:text-left"
                        >
                            {{ $t("home.advantages.lead") }}
                        </p>

                        <div
                            class="grid divide-y-2 divide-r-gray-ultralight text-lg lg:text-xl"
                        >
                            <div
                                class="py-3 flex flex-row items-center gap-4"
                                v-for="advantage in [
                                    'digital_key',
                                    'free_wifi',
                                    'parking',
                                    'always_ready',
                                    'online_checkin',
                                    'contactless',
                                    'support',
                                ]"
                            >
                                <icon
                                    class="stroke-r-green w-6 h-6"
                                    icon="check-circle"
                                ></icon>
                                <span>
                                    {{
                                        $t("home.advantages.items." + advantage)
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 gap-5">
                        <div
                            class="bg-r-green text-white lg:px-20 lg:py-16 px-5 py-8 font-sans rounded-lg grid gap-12"
                        >
                            <h2 class="font-black text-3xl italic text-center">
                                {{ $t("home.customer_satisfaction.heading") }}
                            </h2>
                            <div class="grid gap-3">
                                <div
                                    class="flex justify-center italic font-black text-5xl"
                                >
                                    <div
                                        v-if="recommendation_average !== null"
                                        class="px-3 py-2 bg-r-darkgreen rounded-lg leading-none mt-2"
                                    >
                                        {{ Math.ceil(recommendation_average) }}
                                        %
                                    </div>
                                </div>
                                <div class="text-center text-base">
                                    {{
                                        $t(
                                            "home.customer_satisfaction.recommendation_rate"
                                        )
                                    }}
                                </div>
                            </div>
                            <hr class="b-2" />
                            <div class="grid gap-2">
                                <div
                                    class="flex justify-center"
                                    v-if="rating_average !== null"
                                >
                                    <div
                                        class="bg-white text-r-black flex px-3 py-2 rounded-lg"
                                    >
                                        <div
                                            class="flex flex-row items-center gap-3 text-2xl"
                                        >
                                            <div
                                                class="flex flex-row items-center gap-2"
                                            >
                                                <template
                                                    v-for="counter in [
                                                        1, 2, 3, 4, 5,
                                                    ]"
                                                >
                                                    <font-awesome-icon
                                                        class="text-r-gold"
                                                        icon="star"
                                                        v-if="
                                                            counter <=
                                                            rating_average
                                                        "
                                                    ></font-awesome-icon>
                                                    <font-awesome-icon
                                                        class="text-r-gold"
                                                        :icon="['far', 'star']"
                                                        v-if="
                                                            counter >
                                                            Math.ceil(
                                                                rating_average
                                                            )
                                                        "
                                                    ></font-awesome-icon>

                                                    <font-awesome-icon
                                                        class="text-r-gold"
                                                        icon="star-half-stroke"
                                                        v-if="
                                                            rating_average -
                                                                counter >
                                                                0 &&
                                                            rating_average -
                                                                counter <
                                                                1
                                                        "
                                                    ></font-awesome-icon>
                                                </template>
                                            </div>
                                            <div
                                                class="text-base leading-normal"
                                            >
                                                {{
                                                    $t(
                                                        "home.customer_satisfaction.star_rating",
                                                        {
                                                            rating: rating_average,
                                                            max_rating: "5",
                                                        }
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    {{
                                        $t(
                                            "home.customer_satisfaction.average_info"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="rounded-lg overflow-hidden">
                            <div
                                class="relative max-h-screen h-auto flex w-full justify-self-center justify-center"
                            >
                                <img
                                    src="/img/virtual_tour_image_cropped.jpg"
                                    class="object-cover w-full"
                                />
                                <div
                                    class="absolute inset-0 bg-gradient-to-b from-transparent to-black"
                                ></div>
                                <div
                                    class="absolute left-5 top-5 text-3xl px-5 py-2 bg-white rounded-lg font-black text-r-black italic font-sans"
                                >
                                    {{ $t("home.virtual_tour.heading") }}
                                </div>
                                <div
                                    class="font-bold absolute right-5 bottom-5 text-base leading-4 text-white flex flex-row gap-2 items-center"
                                >
                                    <a
                                        href="https://day.roatel.de"
                                        target="_blank"
                                    >
                                        {{ $t("home.virtual_tour.button") }}
                                    </a>
                                    <icon
                                        icon="chevron-right"
                                        class="w-6 h-6 stroke-white"
                                    ></icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-white">
            <content-container>
                <div class="py-20 px-3 text-center grid gap-8">
                    <h2
                        class="font-sans italic font-black text-r-green text-4xl"
                    >
                        {{ $t("home.news.heading") }}
                    </h2>
                    <p class="text-lg leading-8">
                        {{ $t("home.news.body1") }}
                        <br />
                        <i18n path="home.news.body2" tag="span">
                            <a
                                href="https://roatel.com/agb"
                                class="underline underline-offset-1 hover:cursor-pointer"
                                target="_blank"
                                >{{ $t("home.news.corporate_website") }}</a
                            >
                        </i18n>
                    </p>
                </div>
                <div class="grid lg:grid-cols-3 gap-5">
                    <div
                        v-for="news_item in news"
                        class="rounded-lg overflow-hidden flex flex-col border border-r-gray-light shadow-md pb-3 relative"
                    >
                        <div>
                            <img
                                class="w-full object-cover h-64"
                                :src="news_item.thumbnail"
                                :alt="news_item.thumbnail_alt"
                            />
                        </div>
                        <div class="items-start pb-5">
                            <div class="relative p-5 grid gap-3 flex-grow">
                                <div class="flex gap-3">
                                    <span
                                        v-for="category in news_item.categories"
                                        :key="category"
                                        class="bg-r-gray-ultralight rounded-lg text-r-black text-xs px-2 py-1"
                                        >{{ category }}</span
                                    >
                                </div>
                                <div>
                                    <h3 class="font-bold text-lg">
                                        {{ news_item.title }}
                                    </h3>
                                </div>
                                <div
                                    class="text-sm text-r-black leading-6 pb-10"
                                >
                                    {{ news_item.summary }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="text-sm font-bold px-5 absolute inset-x-0 bottom-5"
                        >
                            <a
                                class="inline-flex items-center gap-3 group"
                                :href="news_item.url"
                                target="_blank"
                                ><span class="group-hover:text-r-green">{{
                                    $t("home.news.item.continue_reading")
                                }}</span>
                                <icon
                                    icon="chevron-right"
                                    class="stroke-r-black w-6 h-6 group-hover:stroke-r-green"
                                ></icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="py-8 text-sm text-center mt-8">
                    <a
                        href="https://roatel.com"
                        class="inline-flex items-center gap-4 group"
                        target="_blank"
                    >
                        <span class="group-hover:text-r-green">
                            {{ $t("home.news.visit_corporate_site") }}
                        </span>
                        <icon
                            class="stroke-r-black group-hover:stroke-r-green w-6 h-6"
                            icon="chevron-right"
                        ></icon>
                    </a>
                </div>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-ultralight">
            <content-container>
                <div class="grid lg:grid-cols-2 gap-8 py-12">
                    <div class="items-center">
                        <h2
                            class="font-sans italic font-black text-r-green text-4xl text-center lg:text-left leading-tight"
                        >
                            {{ $t("home.discover.heading_line1") }}
                            <template v-if="$t('home.discover.heading_line2')">
                                <br />{{ $t("home.discover.heading_line2") }}
                            </template>
                        </h2>
                    </div>
                    <div class="grid gap-4 text-left">
                        <div class="text-center lg:text-left">
                            {{ $t("home.discover.lead") }}
                        </div>
                        <div>
                            <app-input
                                :placeholder="
                                    $t(
                                        'new_reservation.find_roatel_input_placeholder'
                                    )
                                "
                                :hasLabel="false"
                                name="search"
                                v-model="discover_search"
                                @keyup-enter="
                                    $router.push({
                                        name: 'bookings.new.search_properties.geosearch',
                                        params: {
                                            search: discover_search,
                                        },
                                    })
                                "
                                icon="target"
                                iconClickEmit="geosearch"
                                @geosearch="
                                    $router.push({
                                        name: 'bookings.new.search_properties.locationsearch',
                                    })
                                "
                            >
                                <template v-slot:before>
                                    <icon
                                        class="text-r-gray-medium text-xl stroke-r-gray-medium w-6 h-6"
                                        icon="search"
                                    ></icon>
                                </template>
                            </app-input>
                        </div>
                        <div
                            class="flex flex-row items-center justify-between text-r-green"
                        >
                            <div class="mt-2">
                                <router-link
                                    class="inline-flex gap-2 items-center"
                                    :to="{
                                        name: 'bookings.new.search_properties',
                                        params: { show: 'map' },
                                    }"
                                >
                                    <icon
                                        icon="map"
                                        class="stroke-r-green w-6 h-6"
                                    ></icon>
                                    <div>
                                        {{ $t("new_reservation.show_map") }}
                                    </div>
                                </router-link>
                            </div>
                            <div>
                                <app-button
                                    @click="
                                        $router.push({
                                            name: 'bookings.new.search_properties.geosearch',
                                            params: { search: discover_search },
                                        })
                                    "
                                    :disabled="discover_search.length < 2"
                                    color="green"
                                    >{{
                                        $t("new_reservation.find_roatel")
                                    }}</app-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid gap-5 grid-cols-1 lg:grid-cols-3">
                    <property-card
                        v-for="property in latest_properties"
                        :key="property.uuid"
                        :property="property"
                        :show-image="true"
                        :show-address="false"
                        :show-checkin-until="true"
                        :show-open-in-map="true"
                    >
                        <template v-slot:after="{ property }">
                            <div class="mt-5">
                                <app-button
                                    @click="
                                        $router.push({
                                            name: 'bookings.new.set_property',
                                            params: {
                                                property: property.apaleo_id,
                                            },
                                        })
                                    "
                                    >{{
                                        $t(
                                            "new_reservation.property_card.book_property"
                                        )
                                    }}</app-button
                                >
                            </div>
                        </template>
                    </property-card>
                </div>
            </content-container>
        </full-width-container>
        <full-width-container class="bg-r-gray-dark text-white pb-36">
            <content-container>
                <div class="py-8 pb-16 lg:px-72 text-center grid gap-8">
                    <h2
                        class="font-sans italic font-black text-2xl lg:text-4xl leading-relaxed text-center"
                    >
                        {{ $t("home.customer_reviews.heading") }}
                    </h2>
                    <p class="text-lg leading-8">
                        <i18n path="home.customer_reviews.body" tag="span">
                            <a
                                href="https://google.com"
                                class="underline underline-offset-1 hover:cursor-pointer"
                                target="_blank"
                                >{{
                                    $t("home.customer_reviews.google_rating")
                                }}</a
                            >
                        </i18n>
                    </p>
                </div>
                <div class="grid lg:grid-cols-3 gap-5">
                    <div
                        v-for="review in reviews"
                        class="relative bg-r-gray-pretty-dark border border-r-gray-medium rounded-lg text-white p-5"
                    >
                        <div class="text-xl mb-2">
                            <template v-for="counter in [1, 2, 3, 4, 5]">
                                <font-awesome-icon
                                    class="text-r-gold"
                                    icon="star"
                                    v-if="
                                        counter <= review.website_review.rating
                                    "
                                ></font-awesome-icon>
                                <font-awesome-icon
                                    class="text-r-gold"
                                    :icon="['far', 'star']"
                                    v-if="
                                        counter > review.website_review.rating
                                    "
                                ></font-awesome-icon>

                                <font-awesome-icon
                                    class="text-r-gold"
                                    icon="star-half-stroke"
                                    v-if="
                                        review.website_review.rating - counter >
                                            0 &&
                                        review.website_review.rating - counter <
                                            1
                                    "
                                ></font-awesome-icon>
                            </template>
                        </div>
                        <!-- {{ review }} -->
                        <div>{{ review.rating }}</div>
                        <div class="italic font-medium pt-2 pb-10">
                            "{{ review.text }}"
                        </div>
                        <div class="text-sm absolute left-5 bottom-5">
                            {{ review.website_review.source }}
                        </div>
                    </div>
                </div>
            </content-container>
        </full-width-container>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FullWidthContainer from "@guest/common/FullWidthContainer.vue";
import ContentContainer from "@guest/common/ContentContainer.vue";
import HomeBooking from "@guest/HomeBooking.vue";
import AppButton from "@guest/common/Button.vue";
import PropertyCard from "@guest/PropertyCard.vue";
import Icon from "@guest/common/Icon.vue";
import AppInput from "@guest/common/Input.vue";
export default {
    components: {
        FullWidthContainer,
        ContentContainer,
        HomeBooking,
        AppButton,
        Icon,
        PropertyCard,
        AppInput,
    },
    data: function () {
        return {
            latest_properties: [],
            reviews: [],
            news: [],
            recommendation_average: null,
            rating_average: null,
            discover_search: "",
        };
    },
    computed: {
        ...mapState({
            profile: "profile",
            locale: "locale",
        }),
        ...mapGetters({
            isLoggedIn: "isLoggedIn",
        }),
    },
    watch: {
        async locale(val, old) {
            console.log("locale change", this.locale, val, old);
            await this.getReviews();
            await this.getNews();
            //Trigger news and so on
        },
    },
    methods: {
        async getReviews() {
            await axios
                .get("home/website/reviews")
                .then((response) => {
                    this.reviews = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    // this.setLoading(false);
                });
        },
        async getNews() {
            await axios
                .get("home/website/news/3")
                .then((response) => {
                    this.news = response.data.news;
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    // this.setLoading(false);
                });
        },
        async getLatestProperties() {
            this.hasGeolocation = false;
            // this.setLoading(true);
            await axios
                .get("bookings/latest_properties")
                .then((response) => {
                    this.latest_properties = response.data.data;
                    //this.$emit('properties-list')
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    // this.setLoading(false);
                });
        },
        async getAverageRecommendation() {
            await axios
                .get("home/website/average_recommendation")
                .then((response) => {
                    this.recommendation_average = response.data.recommend;
                })
                .catch((error) => {
                    console.error("Cant get recommandation average", error);
                });
        },
        async getAverageRating() {
            await axios
                .get("home/website/average_rating")
                .then((response) => {
                    this.rating_average = response.data.rating;
                })
                .catch((error) => {
                    console.error("Cant get rating average", error);
                });
        },
    },
    async mounted() {
        await this.getLatestProperties();
        await this.getAverageRecommendation();
        await this.getAverageRating();
        await this.getReviews();
        await this.getNews();
    },
};
</script>

<style></style>
