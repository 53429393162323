export default {
    computed: {
        payment_amount() {
            if (this.booking.offer) {
                let amount = 0;
                console.log("calculateBooking", amount);
                //Rooms
                console.log("Calculating Booking Services before room", amount);
                amount +=
                    this.booking.rooms *
                    this.booking.offer.totalGrossAmount.amount;
                console.log("Calculating Booking Services after room", amount);

                this.booking.services.forEach((service) => {
                    console.log(
                        "Calculating Booking Services",
                        service,
                        amount
                    );
                    amount +=
                        this.booking.rooms * service.totalAmount.grossAmount;
                });
                if (this.booking.offer.hasOwnProperty("cityTaxes")) {
                    this.booking.offer.cityTaxes.forEach((tax) => {
                        console.log(
                            "Calculating Booking CityTaxes",
                            tax,
                            amount
                        );
                        amount += tax.totalGrossAmount.amount;
                    });
                }
                return amount;
            }
            return 0;
        },
        city_tax_amount() {
            if (this.booking.offer) {
                let amount = 0;
                if (this.booking.offer.hasOwnProperty("cityTaxes")) {
                    this.booking.offer.cityTaxes.forEach((tax) => {
                        console.log(
                            "Calculating Booking CityTaxes",
                            tax,
                            amount
                        );
                        amount += tax.totalGrossAmount.amount;
                    });
                }
                return amount;
            }
            return 0;
        },
        payment_currency() {
            if (this.booking.offer) {
                return this.booking.offer.totalGrossAmount.currency;
            }
            return "EUR";
        },
    },
};
